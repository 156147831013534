/*Homepage */

.portaljs-home {
  @apply flex flex-col items-center justify-center w-screen bg-orange-50 shadow-xl xl:max-h-[38rem];
}

@media (min-width: 768px) {
  .ourWork li:nth-last-child(1) {
    grid-column-start: 2;
  }
}

.active-nav {
  @apply font-bold;
}

.nav-item {
  @apply text-xs sm:text-sm hover:cursor-pointer hover:font-semibold caret-transparent text-slate-500;
}

.nav-link {
  @apply text-secgray inline-flex items-center mr-2 px-1 pt-1 font-medium hover:text-maingray;
  padding-bottom: 3px;
  background-image: linear-gradient(#fb923c 0 0);
  background-position: 0 100%; /*OR bottom left*/
  background-size: 0% 3px;
  background-repeat: no-repeat;
  transition: background-size 0.3s, background-position 0s 0.3s; /*change after the size immediately*/
}

.nav-link:hover {
  background-position: 100% 100%; /*OR bottom right*/
  background-size: 100% 3px;
}

@media (min-width: 1280px) and (max-width: 1535px) {
  .footer-grid li:nth-last-child(1) {
    grid-column-start: 3;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .footer-grid li:nth-last-child(2) {
    grid-column-start: 2;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  img:not(.bypass-filter) {
    image-rendering: -webkit-optimize-quality !important;
    transform: unset !important;
  }
  svg:not(.bypass-filter) {
    image-rendering: -webkit-optimize-contrast !important;
    transform: none !important;
  }
}

img {
  image-rendering: -webkit-optimize-quality !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #e2e3e4 !important;
}

::-webkit-scrollbar-thumb {
  background: #b6b6b6;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #2a3342;
}

.slider-control-bottomcenter button {
  background: #2a3342 !important;
  border-radius: 50%;
  width: 10px !important;
  height: 10px !important;
}

.slider-control-bottomcenter svg {
  display: none !important;
}
.slider-control-bottomcenter li {
  margin-right: 5px;
}

.odd-position {
  @apply flex justify-center items-center gap-4 hover:scale-105 transition-all transition-all hover:cursor-default text-start lg:text-end flex-nowrap;
}

.even-position {
  @apply flex flex-row-reverse justify-center items-center gap-4 hover:scale-105 transition-all transition-all hover:cursor-default text-start;
}
/** Buttons **/

.btn-primary {
  @apply hover:cursor-pointer inline-flex justify-center items-center py-3 px-7 text-base font-medium text-center text-white rounded-lg bg-orange-400 hover:bg-orange-600 focus:ring-2 focus:ring-orange-400 no-underline caret-transparent transition ease-in-out hover:scale-105 font-['Inter'];
}

.btn-secondary {
  @apply hover:cursor-pointer inline-flex justify-center items-center py-3 px-7 text-base font-medium text-center text-maingray rounded-lg border border-gray-300 hover:bg-[#313131] hover:text-white hover:focus:ring-4 focus:ring-gray-100 shadow-sm no-underline caret-transparent transition ease-in-out hover:scale-105 font-['Inter'] bg-white;
}

/** Text **/

.hero-top-title {
  @apply font-['Inter'] font-extrabold text-4xl md:text-5xl lg:text-[4rem]  tracking-tight lg:leading-tight;
}
.hero-title {
  @apply font-['Inter'] text-3xl md:text-4xl lg:text-5xl font-normal tracking-tight text-maingray;
}
.hero-subtitle {
  @apply font-['Inter'] font-normal md:text-lg  text-secgray;
}

.main-top-title {
  @apply text-4xl lg:text-5xl tracking-tight leading-tight font-bold lg:leading-tight text-maingray;
}

.main-title {
  @apply font-['Inter'] text-lg md:text-xl text-secgray max-w-4xl font-normal;
}

.medium-title {
  @apply text-maingray text-3xl sm:text-3xl lg:text-4xl leading-tight font-bold sm:leading-tight w-full;
}

.small-title {
  @apply font-['Inter'] text-maingray text-lg font-bold;
}

.main-text {
  @apply font-['Inter'] text-secgray font-medium;
}

.gray-subtitle {
  @apply font-medium leading-6 text-[#8896AB];
}

.features-text {
  @apply text-lg text-secgray;
}

/** Article Layout Directives **/

.key-facts section {
  @apply grid grid-cols-2 gap-x-8;
}

.key-facts div {
  @apply bg-infoicon h-5 w-5 bg-contain shrink-0 mt-6;
}

.key-facts kf {
  @apply flex gap-x-2;
}

kf p {
  @apply !m-0 !p-0
}


.kf-resolution {
  @apply bg-[#f2f3f5] border-l-4 p-8 border-orange-400 my-10;
}

/** Tech Stack Icons **/

.tech-container {
  @apply grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 caret-transparent gap-x-2 gap-y-6 pt-4 pb-4;
}

/* Custom Callout */
.callout {
  @apply border-l-4 !px-8 !py-2 border-orange-400 my-10 bg-transparent;
}

.keynote {
  @apply border-l-4 !px-8 py-2 border-orange-400 mt-7 mb-10;
}

.note {
  @apply !px-8 py-2 rounded-2xl mt-7 mb-10;
}

.descp > a > span {
  @apply underline text-maingray;
}

dd > a > span {
  @apply underline text-maingray;
}

.dpwhite > a > span {
  @apply underline text-orange-100;
}

/* Blog */
.active-filter {
  @apply font-semibold rounded-lg inline-flex items-center px-2 py-2 transition-all text-maingray focus:text-white ease-in-out focus:bg-orange-400 gap-1 capitalize focus:outline-none hover:text-orange-400;
}

.blog-categories {
  @apply flex flex-wrap justify-start gap-3 caret-transparent min-w-full w-full;
}

.pagination {
  @apply flex justify-center col-span-full gap-4 font-['Inter'] mt-16;
}

.page-number {
  @apply font-['Inter'] hover:text-orange-400 active:text-orange-400 font-semibold text-maingray;
}

.active-page {
  @apply text-orange-400;
}

.event {
  @apply grid grid-cols-1 lg:grid-rows-1 lg:grid-cols-12 w-full mt-14;
}

.event-image {
  @apply object-cover w-full h-[22rem] shadow-lg lg:rounded-lg lg:border ease-in duration-500 group-hover:scale-105 transform;
}

.event-info {
  @apply mx-auto w-full col-span-full lg:row-span-full lg:col-start-6 lg:col-span-12 py-16 px-4 lg:px-16 lg:w-full bg-white shadow-lg lg:rounded-lg;
}
/*Case Studies*/

.service-item {
  @apply grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 caret-transparent min-w-full w-full pt-8;
}

.btn-filter {
  @apply font-semibold text-start rounded-lg px-2 text-sm transition-all text-secgray focus:text-white ease-in-out focus:text-orange-400 gap-1 capitalize hover:text-orange-400;
}

.showcase-casestudies {
  @apply lg:w-1/2 h-full aspect-[5/4] font-['Inter'] w-full h-full lg:max-w-none;
}

.showcase-dataportals {
  @apply lg:w-1/2 h-full aspect-[5/4] font-['Inter'] w-full h-full lg:max-w-none;
}

.showcase-component {
  @apply flex flex-col lg:flex-row justify-center items-center gap-x-8 gap-y-16 transition-all pb-8 pt-32 px-8;
}
.showcase-style a {
  @apply underline font-semibold;
}

.basetech {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 3rem; /* Tailwind's h-12 class equivalent (48px) */
    width: 4rem;  /* Tailwind's w-16 class equivalent (64px) */
    margin: 0 auto; /* Tailwind's mx-auto */
}

.tech-API {
  background-image: url('/assets/icons/tech/API.png');
}


.tech-AWS {
  background-image: url('/assets/icons/tech/AWS.png');
}


.tech-Actions {
  background-image: url('/assets/icons/tech/Actions.png');
}


.tech-Airflow {
  background-image: url('/assets/icons/tech/Airflow.png');
}


.tech-Apollo {
  background-image: url('/assets/icons/tech/Apollo.png');
}


.tech-Azure {
  background-image: url('/assets/icons/tech/Azure.png');
}


.tech-BigQuery {
  background-image: url('/assets/icons/tech/BigQuery.png');
}


.tech-CKAN {
  background-image: url('/assets/icons/tech/CKAN.png');
}


.tech-Chai {
  background-image: url('/assets/icons/tech/Chai.png');
}


.tech-Cypress {
  background-image: url('/assets/icons/tech/Cypress.png');
}


.tech-DBT {
  background-image: url('/assets/icons/tech/DBT.png');
}


.tech-DCAT {
  background-image: url('/assets/icons/tech/DCAT.png');
}


.tech-DNNS {
  background-image: url('/assets/icons/tech/DNNS.png');
}


.tech-Datatables {
  background-image: url('/assets/icons/tech/Datatables.png');
}


.tech-Docker {
  background-image: url('/assets/icons/tech/Docker.png');
}


.tech-EKS {
  background-image: url('/assets/icons/tech/EKS.png');
}


.tech-ElasticSearch {
  background-image: url('/assets/icons/tech/ElasticSearch.png');
}


.tech-FastAPI {
  background-image: url('/assets/icons/tech/FastAPI.png');
}


.tech-Figma {
  background-image: url('/assets/icons/tech/Figma.png');
}


.tech-Firebase {
  background-image: url('/assets/icons/tech/Firebase.png');
}


.tech-GCloud {
  background-image: url('/assets/icons/tech/GCloud.png');
}


.tech-GDS {
  background-image: url('/assets/icons/tech/GDS.png');
}


.tech-Ghost {
  background-image: url('/assets/icons/tech/Ghost.png');
}


.tech-GraphQL {
  background-image: url('/assets/icons/tech/GraphQL.png');
}


.tech-Hasura {
  background-image: url('/assets/icons/tech/Hasura.png');
}


.tech-JavaScript {
  background-image: url('/assets/icons/tech/JavaScript.png');
}


.tech-Jenkins {
  background-image: url('/assets/icons/tech/Jenkins.png');
}


.tech-Jina {
  background-image: url('/assets/icons/tech/Jina.png');
}


.tech-JsonLD {
  background-image: url('/assets/icons/tech/JsonLD.png');
}


.tech-Jupyter {
  background-image: url('/assets/icons/tech/Jupyter.png');
}


.tech-Kibana {
  background-image: url('/assets/icons/tech/Kibana.png');
}


.tech-Kubernetes {
  background-image: url('/assets/icons/tech/Kubernetes.png');
}


.tech-Leaflet {
  background-image: url('/assets/icons/tech/Leaflet.png');
}


.tech-MSSQL {
  background-image: url('/assets/icons/tech/MSSQL.png');
}


.tech-MachineLearning {
  background-image: url('/assets/icons/tech/MachineLearning.png');
}


.tech-MapBox {
  background-image: url('/assets/icons/tech/MapBox.png');
}


.tech-NLP {
  background-image: url('/assets/icons/tech/NLP.png');
}


.tech-Nodejs {
  background-image: url('/assets/icons/tech/Nodejs.png');
}


.tech-Plotly {
  background-image: url('/assets/icons/tech/Plotly.png');
}


.tech-PortalJS {
  background-image: url('/assets/icons/tech/PortalJS.png');
}


.tech-Postgre {
  background-image: url('/assets/icons/tech/Postgre.png');
}


.tech-Prefect {
  background-image: url('/assets/icons/tech/Prefect.png');
}


.tech-PyVis {
  background-image: url('/assets/icons/tech/PyVis.png');
}


.tech-Python {
  background-image: url('/assets/icons/tech/Python.png');
}


.tech-React {
  background-image: url('/assets/icons/tech/React.png');
}


.tech-Redis {
  background-image: url('/assets/icons/tech/Redis.png');
}


.tech-Redshift {
  background-image: url('/assets/icons/tech/Redshift.png');
}


.tech-SDMX {
  background-image: url('/assets/icons/tech/SDMX.png');
}


.tech-Scrapy {
  background-image: url('/assets/icons/tech/Scrapy.png');
}


.tech-Solr {
  background-image: url('/assets/icons/tech/Solr.png');
}


.tech-Tailwind {
  background-image: url('/assets/icons/tech/Tailwind.png');
}


.tech-TypeScript {
  background-image: url('/assets/icons/tech/TypeScript.png');
}


.tech-Umami {
  background-image: url('/assets/icons/tech/Umami.png');
}


.tech-Watson {
  background-image: url('/assets/icons/tech/Watson.png');
}


.tech-uWSGI {
  background-image: url('/assets/icons/tech/uWSGI.png');
}


.tech-Nextjs {
  background-image: url('/assets/icons/tech/Nextjs.png');
}


.tech-Mocha {
  background-image: url('/assets/icons/tech/Mocha.png');
}


.tech-D3 {
  background-image: url('/assets/icons/tech/D3.png');
}


.tech-Mapbox {
  background-image: url('/assets/icons/tech/Mapbox.png');
}


.tech-Aircan {
  background-image: url('/assets/icons/tech/Aircan.png');
}


.tech-Datapub {
  background-image: url('/assets/icons/tech/Datapub.png');
}


.tech-CSV {
  background-image: url('/assets/icons/tech/CSV.png');
}


.tech-Cloudflare-R2 {
  background-image: url('/assets/icons/tech/Cloudflare-R2.png');
}


.tech-Cloudflare {
  background-image: url('/assets/icons/tech/Cloudflare.png');
}


.tech-ETL {
  background-image: url('/assets/icons/tech/ETL.png');
}


.tech-FTP {
  background-image: url('/assets/icons/tech/FTP.png');
}


.tech-Frictionless-Data {
  background-image: url('/assets/icons/tech/Frictionless-Data.png');
}


.tech-GitHub-Actions {
  background-image: url('/assets/icons/tech/GitHub-Actions.png');
}


.tech-GitHub {
  background-image: url('/assets/icons/tech/GitHub.png');
}


.tech-ExpressJS {
  background-image: url('/assets/icons/tech/ExpressJS.png');
}


.tech-Hetzner {
  background-image: url('/assets/icons/tech/Hetzner.png');
}


.tech-MarkdownDB {
  background-image: url('/assets/icons/tech/MarkdownDB.png');
}


.tech-DataCite {
  background-image: url('/assets/icons/tech/DataCite.png');
}


.tech-Google-Analytics {
  background-image: url('/assets/icons/tech/Google-Analytics.png');
}


.tech-Google-Tag-Manager {
  background-image: url('/assets/icons/tech/Google-Tag-Manager.png');
}


.tech-Excalidraw {
  background-image: url('/assets/icons/tech/Excalidraw.png');
}


.tech-Tableau {
  background-image: url('/assets/icons/tech/Tableau.png');
}


.tech-IGV {
  background-image: url('/assets/icons/tech/IGV.png');
}


.tech-R2-Bucket-Uploader {
  background-image: url('/assets/icons/tech/R2-Bucket-Uploader.png');
}

