@import "@portaljs/remark-callouts/styles.css";

.nav-logo {
  height: 1.5rem;
}



/* mathjax */
.math-inline > mjx-container > svg {
  display: inline;
  align-items: center;
}

/* smooth scrolling in modern browsers */
html {
  scroll-behavior: smooth !important;
}

/* tooltip fade-out clip */
.tooltip-body::after {
  content: "";
  position: absolute;
  right: 0;
  top: 3.6rem; /* multiple of $line-height used on the tooltip body (defined in tooltipBodyStyle) */
  height: 1.2rem; /* ($top + $height)/$line-height is the number of lines we want to clip tooltip text at*/
  width: 10rem;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 100%
  );
}

/* hide anchor link with # icon  */
.unstyled :is(h1, h2, h3, h4, h5, h6) > a {
  visibility: hidden;
}

.docs :is(h1, h2, h3, h4, h5, h6) {
  margin-left: -2rem !important;
  padding-left: 2rem !important;
  scroll-margin-top: 4.5rem;
  position: relative;
}

.docs :is(h1, h2, h3, h4, h5, h6) > a {
  padding: 1px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: auto 0;
  border-radius: 5px;
  background: #1e293b;
  opacity: 0;
  transition: opacity 0.2s;
}

.light .docs :is(h1, h2, h3, h4, h5, h6) > a {
  /* border: 1px solid #ab2b65; */
  /* background: none; */
  background: #e2e8f0;
}

.docs :is(h1, h2, h3, h4, h5, h6):hover > a {
  opacity: 100;
}

.docs :is(h1, h2, h3, h4, h5, h6) > a > svg {
  transform: scale(0.75);
}

@media screen and (max-width: 640px) {
  .docs :is(h1, h2, h3, h4, h5, h6) > a {
    visibility: hidden;
  }
}
